.support-container {
    align-items: center;
    background-color: #11151e;
    color: #919191;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 1vmin);
    height: 100vh;
    justify-content: center;
    margin: 0;
  }
  
  .support-container h2 {
    color: #ccc;
  }
  
  